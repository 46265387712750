.App {
    /*background: linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)),url("background.png") fixed #444 center;*/
    /*-webkit-background-size: cover;*/
    /*-moz-background-size: cover;*/
    /*-o-background-size: cover;*/
    /*background-size: cover;*/
    color: #444;
    height: 100%;
    overflow: scroll;

}

/*#root {*/
/*position: fixed;*/
/*bottom: 0px;*/
/*top: 0px;*/
/*left: 0px;*/
/*right: 0px;*/
/*}*/
.header {
    height: 100%;
    text-align: center;
    position: relative;
    /*width: 960px;*/
    overflow: scroll;
    margin: 0 auto;
    padding: 0;
}

@media only screen and (max-width: 700px) {
    header {
        background-color: lightblue;
        overflow: scroll;
    }

    .hCon {
        padding-top: 50px !important;
        padding-bottom: 70px;
    }

    .payment {
        margin: 10px;
    }
}

.body {
    background: white;
}

.box_1 {
    position: relative;
    width: 960px;
    margin: 0 auto;
    padding: 50px 0px;
}

.b1_sub1 {
    display: flex;
    margin-bottom: 40px;
}

.b1s_title {
    flex: 1;
}

.b1s_title p {
    margin: 0px;
    font-size: 48px;
    line-height: 52px;
    font-weight: bold;
    color: #444444;
}

.b1s_subtitle {
    flex: 1;
}

.b1s_subtitle p {
    margin: 0px;
    font-size: 22px;
    line-height: 30px;
    color: #6b6b6bbb;
}

.tBoe {

    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
    display: inline-block;
}

.b1_sub2 {
    display: flex;
}

.b1_sub2 > div {
}

.tDnPoe {
    font-size: 16px;
    line-height: 24px;
    color: #999;
}

.hCon {
    padding-top: 150px;
    /*width: 700px;*/
    /*padding-left: 150px;*/
}

.hCon > p:nth-child(1) {
    padding: 10px;
    margin: 0px;
    color: white;
    font-size: 48px;
    line-height: 52px;
    font-weight: bold;
    text-align: center;
}

.hCon > p:nth-child(2) {
    margin: 10px;
    fint-size: 18px;
    color: #737373;
    font-style: italic;
}

.hCon > p:nth-child(3) {
    color: #dcdcdc;
    font-size: 37px;
    line-height: 30px;
    margin: 0px;
    padding: 10px;
    text-align: center;
}

.hCon > p:nth-child(4) {
    color: #bbb;
    font-size: 22px;
    line-height: 30px;
    margin: 0px;
    padding: 10px;
    text-align: center;
}

.hCon > a > i {
    margin-right: 10px;
}

.hCon > a:nth-child(5), .hCon > a:nth-child(6) {
    text-decoration: none;
    padding: 12px 16px;
    border-radius: 3px;
    margin: 5px 0;
    display: inline-block;
    -webkit-transition: all .2s linear;
    color: #fff !important;
    box-shadow: 0px 0px 0px 2px inset;
    padding: 15px 22px !important;
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    margin: 10px;
}

.hCon > a:nth-child(6) {
    background: #FF9800;
}

.hBot {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 20px;
    text-align: center;
    background: #202121;
    border-top: 1px solid #191919;
}

.hBot > a {
    font-size: 20px;
    color: white;
    opacity: 0.6;
}

.hBot > a:hover {
    opacity: 1;
    cursor: pointer;
}

.buyApp, .watchVideo {
    cursor: pointer;
}

.payment {
    background: white;
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
    margin: 10px auto;
}

.paymentSupport {
}

.payPal {
    background-image: url("img/paypal_logo.png");
    background-size: cover;
    background-color: white;
    width: 70px;
    height: 26px;
    display: inline-block;
    margin: 5px;
    cursor: pointer;
    border: 1px solid #a2a2a2;
}

.transferWise {
    background-image: url("img/transferwise_logo.png");
    background-size: cover;
    background-color: white;
    width: 70px;
    height: 26px;
    display: inline-block;
    margin: 5px;
    cursor: pointer;
    border: 1px solid #a2a2a2;
}

.paymentInfo {
    text-align: left;
}

.payPalInfo, .transferWiseInfo {
    display: flex;
    flex-direction: column;
}

.payPalInfo a, .transferWiseInfo a {
    margin: 3px;
}

.payPalInfo span, .transferWiseInfo span {
    font-weight: bold;
}

.nodePayment {
    padding-top: 10px;
    color: #55526f;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    text-decoration: unset;
}
.nodePayment span {
    color: #8482c0;
}
.nodePayment i {
    margin-right: 7px;
    color: #4d4379;
}


.section-header.section-category {
    /*height: 60vh;*/
}

.section {
    margin: 0;
    position: relative;
}

[class*="gradient-"]::after {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    top: 0;
    left: 0;
    z-index: 0;
}

.gradient-blue::after {
    background: linear-gradient(-45deg, #875A7B 0%, #51c9f5 100%);
}

.section-header {
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
}

.section-header {
    /*height: 92vh;*/
    max-height: 350px;
    display: flex;
    align-items: center;
    background-color: #36275d;
}

.filter-bar {
    background-color: rgba(255, 255, 255, 0);
    border-color: transparent;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    padding: 15px;
}

.section-header .info {
    text-shadow: none;
}

.section-bundle .info, .section-header .info {
    position: relative;
    z-index: 1;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
}

.section-all-products .info {
    color: #FFFFFF;
    max-width: 720px;
    margin: 120px auto;
}

.section-header .info h1, .section-header .info p {
    font-weight: 300;
}

.section-category.section-header .info p {
    font-size: 20px;
}

.section-header p {
    font-size: 18px;
    opacity: .9;
}

.section-header h1 {
    margin-bottom: 40px;
    margin-top: 30px;
}

.navbar-search-form {
    position: relative;
    z-index: 1;
}

.form-search {
    max-width: 650px;
    padding: 0 15px;
    margin: 30px auto 0;
    position: relative;
}

.section-header .form-search {
    padding-left: 0;
    margin-top: 50px;
}

.section-category .form-control-search {
    background-color: rgba(255, 255, 255, 0.1);
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-search .form-control-search {
    background-color: rgba(115, 186, 221, 0.35);
    box-shadow: none;
    border: medium none;
    border-radius: 30px;
    box-shadow: 0 0 0;
    color: #FFFFFF;
    display: block;
    font-size: 20px;
    font-weight: 300;
    height: 50px;
    line-height: 1.42857;
    padding: 6px 20px;
    vertical-align: middle;
    transition: background-color .2s;
}

.form-search .form-control-search::placeholder {
    color: white;
}

.form-search .btn-submit {
    position: absolute;
    right: 18px;
    border: none;
    padding: 10px 12px;
    height: 44px;
    line-height: 30px;
    width: 44px;
    display: block;
    top: 3px;
    opacity: .85;
    background: #FFFFFF;
    color: #666666;
    transition: all .2s;
}

.btn-white {
    background-color: #FFFFFF;
    color: #555555;
}

.btn-round {
    border-width: 1px;
    border-radius: 30px !important;
    opacity: 0.79;
    padding: 9px 18px;
}

.form-search .btn-submit .icon-search {
    font-size: 18px;
}

.form-search .btn i {
    font-size: 21px;
    color: #ababab;
}

@media (min-width: 1200px) {
    .section-header h1 {
        font-size: 36px;
    }
}


.card .title, .card .actions a, .card .description, .card .numbers, .card .name, .card-info, .card-info h3 {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.main {
    background-color: #FFFFFF;
    position: relative;
    padding-top: 50px;
}

.main .title {
    font-size: 22px;
    font-weight: 300;
    margin: 7px 0;
    color: #777;
}

.landing-title span.title, .landing-title h2.title {
    font-size: 28px;
}

.landing-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -10px;
    margin-bottom: 23px;
}

.card {
    border-radius: 8px;
    position: relative;
    margin-bottom: 60px;
}

.card .thumbnail {
    border: 0 none;
    padding: 0;
    margin: 0;
    min-height: 250px;
    position: relative;
    background: transparent;
    box-shadow: 0 25px 20px -21px rgba(0, 0, 0, 0.57);
}

.card .thumb-cover {
    padding: 15px 20px;
    height: 100%;
    top: 0;
    position: absolute;
    opacity: 0;
    display: block;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 3;
    content: "";
    left: 0;
    border-radius: 8px;
}

.card .details {
    top: 0;
    display: block;
    height: 60px;
    padding: 10px 15px 0;
    position: absolute;
    width: 100%;
    border-radius: 8px 8px 0 0;
}

.card .numbers {
    color: #FFFFFF;
    float: right;
    margin-top: 6px;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.34);
}

.card .numbers .downloads, .card .numbers .comments-icon {
    margin-left: 6px;
    font-size: 15px;
    font-weight: 500;
}

.card .numbers .fa {
    font-size: 18px;
}

.card .thumbnail > img {
    border-radius: 8px 8px;
}

.card .thumbnail img {
    width: 100%;
}

.thumbnail > img, .thumbnail a > img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
}

.card-info {
    padding: 15px;
    border-radius: 0 0 12px 12px;
}

.card-info a:not(.btn) {
    color: #434343;
}

.card-info h3 {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 18px;
}

.card .time {
    color: #777777;
    font-size: 15px;
    margin-top: 2px;
    text-transform: uppercase;
}

.card-info p {
    font-size: 14px;
    margin: 0;
    color: #666666;
    min-height: 50px;
}

/*Footer*/
footer {
    width: 100%;
    height: auto;
    background-color: #282F33;
    color: #888888;
}

.footer > .container {
    display: flex;
}

.footer .contact {
    flex: 1;
    text-align: right;
    color: white;
    font-size: 14px;
}

.contact span {
    margin-left: 20px;
}

.contact span i {
    margin-right: 5px;
}

footer .main {
    background: #282f33;
}

.logo {
    height: 100px;
    width: 100px;
    position: absolute;
    top: -40px;
    left: 50%;
    cursor: pointer;
    transform: translate(-50%, -50%);
}

.logo img {
    opacity: 0.5;
    width: 100%;
}

.logo img:hover {
    opacity: 1;
}

footer .main {
    padding: 10px;
}

footer .WnMdF {
    display: flex;
}

footer .WnMdF li {
    padding: 5px;
}

footer ul {
    list-style: none;
    margin-bottom: 0px;
}

footer .nMdF2 {
    width: 25%;
    padding: 10px;
}

footer .nMdF2:first-child a {
    color: #fff;
    text-transform: uppercase;
    font-size: 13px;
}

footer .nMdF2 li:first-child {
    padding: 5px 2px;
}

footer .nMdF2 li:first-child a {
    font-size: 14px;
    color: white;
}

footer .nMdF2 li {
    padding: 2px;
}

footer .nMdF2 li a {
    font-size: 12px;
}

footer .icon a {
    padding: 7px;
}

footer svg:hover {
    fill: white;
}

footer .footer {
    background-color: #21272b;
    color: #dcddde;
    padding: 1em 0;
}

footer .footer .cpRight {
    display: inline-block;
}

footer .footer .cpRight span {
    font-size: 14px;
    color: #919496;
}

footer .clPrK {
    float: right;
}

footer .clPrK li {
    display: inline-block;
    padding: 2px 10px;
}

footer .clPrK li a {
    font-size: 12px;
    color: #919496;
}

footer .icon {
    text-align: right;
}

.wPaC .title.h1 {
    font-size: 18px;
    color: #cdcdcd;
}

.rowOs {
    padding-bottom: 40px;
    border-bottom: 1px solid #3d4548;
}

.rowPs {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.rowPs h2 {
    color: #cdcdcd;
}

.d-flex {
    display: flex;
}

.wIcons {
    align-self: flex-end;
}

.wJoin {
    display: flex;
}

footer h1, footer .h1 {
    font-size: 22px;
    font-weight: 400;
    margin: 0;
    line-height: 40px;
}

footer .title.h1 {
    margin-bottom: 15px;
}

footer input[type="email"] {
    border-radius: 3px;
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    font-size: 16px;
    height: 40px;
    margin-right: 15px;
    padding: 3px 12px;
    width: 100%;
}

.wJoin button {
    background-color: #00BBFF;
    border-color: #00BBFF;
}

/*Product*/
.wProduct {
    padding: 100px;
}

.product-image {
    box-shadow: 0 29px 32px -20px rgba(0, 0, 0, 0.35);
    margin: 0 auto 30px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    border-radius: 20px;
}

.product-image img {
    width: 100%;
}

.product-details {
    font-size: 16px;
    font-weight: 200;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.product-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: .75rem;
}

.product-description {
    color: #5c6577;
    margin-bottom: 1.5rem;
    font-weight: 400;
}

.btnBuy.btn-round, .btnDocs.btn-round {
    box-shadow: 0 5px 15px rgba(134, 91, 125, 0.2), 0 15px 40px rgba(94, 186, 229, 0.2);
    background: #57c3ee;
    color: #fff;
    opacity: 1;
    padding: 10px 16px;
    font-size: 16px;
    margin-right: 20px;
}

.btnDocs.btn-round {
    border: 1px solid #ebebeb;
    background: transparent;
    box-shadow: none;
    color: #5c6577;
    font-size: 14px;
}

.btnDocs i {
    margin-right: 5px;
}

.product-title h1 {
    margin-bottom: 0px;
    padding-bottom: 15px;
    font-size: 32px;
    margin-top: 0px;
}

.meta-data {
    padding: 0;
    margin: 0 0 1.2rem 0;
    list-style: none;
}

.meta-data li {
    text-transform: uppercase;
    color: #5c6577;
    font-size: 12px;
}

.meta-data li strong {
    color: #9ca6b9;
}

.wHead {
    padding: 30px 20px;
    margin-bottom: 30px;
}

.wHead h4 {
    color: #5d5d5d;
    font-weight: 300;
}

.area_features {
    margin-bottom: 100px !important;
}

.area_features h5 {
    font-size: 20px;
    padding: 15px 10px 5px 10px;
}

.area_features p {
    font-size: 15px;
    color: dimgrey;
}

.display-3 {
    font-size: 44px;
    font-family: ui-serif;
}

.o_overview {
    margin-bottom: 70px;
}

.o_title {
    text-align: center;
}

.o_title h1 {
    font-family: ui-serif;
    font-size: 90px;
    font-weight: 700;
    letter-spacing: -3px;
    line-height: 1.3;
}

.o_description {
    margin: 28px auto;
    line-height: 1.6;
    letter-spacing: -0.7px;
    max-width: 600px;
    font-size: 22px;
    text-align: center;
    margin: auto;
}

.o_description p {
    color: #4a4a4a;
}

.o_buttons {
    text-align: center;
    padding: 20px;
}

.o_buttons button, .o_buttons a {
    margin-right: 10px;
    /*background: rgb(255, 102, 130);*/
    /*border: 1px solid rgb(246, 90, 119);*/
    /* border: 0px; */
    border-radius: 5px;
    padding: 10px 20px;
    display: inline-block;
    /*color: white;*/
    font-weight: bold;
    cursor: pointer;
}

.o_buttons .btnBuy {
    /*margin-right: 10px;*/
    background: rgb(255 165 182);
    border: 1px solid rgb(246, 90, 119);
    /* border: 0px; */
    /*border-radius: 5px;*/
    padding: 10px 30px;
    color: white;
    /*font-weight: bold;*/
}

.btnBuy:hover {
    background-color: rgb(255 142 163);
}

.o_buttons .btnWatch {
    /*margin-right: 10px;*/
    background: rgb(255 255 255);
    border: 1px solid rgb(255 103 130);
    /* border: 0px; */
    /*border-radius: 5px;*/
    /*padding: 10px 20px;*/
    color: #ff6782;
    /*font-weight: bold;*/
}

.btnWatch:hover {
    background-color: rgb(255 242 242);
}

.btnWatch:active, .btnWatch:focus {
    border: 1px solid rgb(255 103 130);
    outline: none;
}

.o_more {
    text-align: center;
    margin-bottom: 20px;
    color: rgb(153, 150, 156);
}

.imgLove {
    width: 230px;
    margin: 0px auto -27px auto;
}

.imgOverview {
    width: 100%;
    border: 10px solid rgba(25, 25, 26, 0.09);
    box-shadow: rgba(38, 44, 61, 0.2) 0px 30px 100px;
    border-radius: 24px;
    overflow: hidden;
}

.imgOverview img, .imgLaying img {
    width: 100%;
}

.imgLaying {
    margin-top: -100px;
    position: absolute;
    margin-right: -100px;
    margin-bottom: -100px;
    bottom: 0px;
    right: 0px;
    width: 300px;
}

.text-center {
    text-align: center !important;
}

.mb48 {
    margin-bottom: 48px !important;
}

.o-icon {
    border-radius: 50%;
    text-align: center;
}

.o-icon[class*="text-"] {
    border: 1px solid rgb(236 42 78);
    background: rgb(255 128 151);
}

.o-icon.fa.fa-2x {
    width: 80px;
    display: inline-block;
    height: 80px;
    line-height: 80px;
    color: white;
}

.studio {
    overflow: hidden;
    font-family: monospace;
    letter-spacing: -0.06em;
}

.studio .main {
    margin-bottom: 200px;
}

.studio footer, .studio footer .main {
    background-color: white !important;

}

.studio .footer {
    background-color: #ffa3b3;
}

.studio footer .main {
    margin-bottom: 150px;
}

.studio .footer .cpRight span {
    color: white;
}

.studio .wJoin input {
    border: 1px solid #bbc4c7;
    border-radius: 30px;
    padding-left: 30px;
    color: #5f6669;
}

.contact {
    margin: auto;
}

.titleCon h1 {
    display: block;
    font-size: 60px;
    font-weight: 700;
    letter-spacing: -1.6px;
    margin: 5px 14px;
    line-height: 1;
    text-align: center;
    font-family: ui-serif;
}

.titleCon h1 a {
    text-decoration: unset;
}

.titleCon {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    /* align-items: center; */
    justify-content: center;
}

.titleIcon {
    padding: 20px;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgb(255, 240, 243);
    color: rgb(43, 42, 45);
    border-radius: 50px;
    font-size: 34px;
    height: 80px;
    width: 80px;
    max-height: 80px;
    max-width: 80px;
    margin-right: 4px;
}

.titleIcon img {
    width: 100%;
}

.titleDes {
    text-align: center;
    padding: 30px;
}

.titleDes h3 {
    font-size: 18px;
    font-weight: 400;
    max-width: 80vw;
    width: 500px;
    text-align: center;
    color: rgb(107, 104, 110);
    letter-spacing: -0.01em;
    line-height: 1.6;
    /* margin-top: 30px; */
    margin: auto;
}

/*Pricing*/
.wPr {
    display: grid;
    margin: 50px auto;
    width: 990px;
    max-width: 94%;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2em;
}

.prOption {
    align-items: flex-start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    letter-spacing: -0.025em;
    /*padding: 20px;*/
    margin: 0px;
    border: 1px solid rgba(25, 25, 26, 0.06);
    background: rgb(252, 252, 253);
    border-radius: 20px;
    z-index: 100;
    transition: all 0.2s ease 0s;
    cursor: pointer;
    user-select: none;
}

.prOption:hover {
    background-color: white;
    border: 0px;
    transform: scale(1.02);
    box-shadow: rgba(38, 44, 61, 0.2) 0px 30px 100px;
}

.prOption .wIcon {
    padding: 15px;
    margin-left: 27px;
    margin-top: 27px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    border-radius: 50px;
    font-size: 26px;
    height: 60px;
    width: 60px;
    margin-bottom: 10px;
}

.prOption[type='started'] .wIcon {
    background-color: rgb(35, 132, 251);
    filter: drop-shadow(rgba(102, 159, 255, 0.4) 0px 6px 18px);
}

.prOption[type='unlimited'] .wIcon {
    background-color: rgb(0, 199, 126);
    filter: drop-shadow(rgba(102, 255, 136, 0.4) 0px 6px 18px);
}

.prOption[type='lifetime'] .wIcon {
    background-color: rgb(230, 192, 117);
    filter: drop-shadow(rgba(204, 190, 0, 0.25) 0px 5.42017px 13.0084px);
}

.prOption .wIcon img {
    height: 100%;
}

.wInfo h3 {
    font-size: 32px;
    margin: 15px 0px 10px 27px;
    font-weight: 700;
    letter-spacing: -0.026em;
    line-height: 100%;
}

.wInfo p {
    font-size: 15px;
    margin-left: 27px;
    max-width: 230px;
    line-height: 140%;
    color: rgb(92, 89, 95);
    font-weight: 400;
}

.wInfo .price span {
    font-size: 45px;
    margin-left: 27px;
    max-width: 230px;
    line-height: 140%;
    color: rgb(43 64 80);
    font-weight: 600;
}

.wFeatures {
    border-top: 1px solid rgba(25, 25, 26, 0.06);
    margin: 10px;
    padding: 10px 10px 10px 20px;
}

.wFeatures > div {
    padding: 5px 10px;
    font-size: 15px;
    color: #63758a;
}

.wFeatures i {
    font-size: 12px;
    /*margin-right: 8px;*/
}

.o_payment .payment {
    border: 10px solid rgba(25, 25, 26, 0.09);
    box-shadow: rgba(38, 44, 61, 0.2) 0px 30px 100px;
    border-radius: 24px;
    overflow: hidden;
}

.o_payment .paymentSupport {
    text-align: center;
    padding: 10px;
}

.o_payment .paymentSupport a {
    border: 0px;
    opacity: 0.5;
}

.o_payment .paymentSupport a:hover, .o_payment .paymentSupport a.active {
    opacity: 1;
}

.paymentInfo {
    background: #fff3f5;
    padding: 20px;
    border-radius: 10px;
}

.wDashboard {
    margin-top: 50px;
}

@media only screen and (max-width: 700px) {
    .wPr {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 450px) {

    .titleCon h1 {
        font-size: 30px;
    }

    .wInfo h3 {

        font-size: 27px;
    }

    .wInfo .price span {
        font-size: 35px;
    }

    .footer > .container {
        flex-direction: column;
    }

    .footer .contact {
        text-align: left;
    }

    .footer .contact span {
        display: inline-block;
        margin-left: 0px;
    }
}



